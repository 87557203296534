import { REHYDRATE } from "redux-persist/lib/constants";
import {
  SET_ERROR,
  SET_IS_LOADING,
  SET_IS_SAVING,
  SET_TOKEN,
  IS_LOGGING_IN,
  LOGOUT,
  SET_LANGUAGE,
  SET_GLOBAL_LANGUAGES,
  SET_GLOBAL_SETTINGS,
  SET_USER,
  SET_SESSION_STARTED_AT,
  SET_HAS_ANIMATION,
} from "./constants";

export interface IAppState {
  isRehydrated: boolean;
  isLoading: boolean;
  isSaving: boolean;
  error: IError;
  user: IUser;
  token: string;
  isLoggingIn: boolean;
  lang: { locale: string; id: number };
  globalLangs: any;
  globalSettings: IGlobalSettings;
  sessionStartedAt: null | Date;
  hasAnimation: boolean;
}
export interface IError {
  code?: number;
  text: string;
  btnText?: string | undefined;
  actionsBelow?: boolean | undefined;
  subErrors?: any[];
  btnAction?: () => void;
}

export interface IUser {
  email: string;
}

export interface IGlobalSettings {
  cloudinaryName: string | null;
  globalLogoBig: string;
  globalLogoMini: string;
  siteLogoBig: string;
  siteLogoMini: string;
  welcomeText1: string;
  welcomeText2: string;
  skipReviewOnPublish?: boolean;
  autoSummary: boolean;
  autoTranslation: boolean;
  SSOActivated: boolean;
  SSOWelcomeText: string;
}

export const initialState = {
  isRehydrated: true,
  isLoading: true,
  isSaving: false,
  error: {
    code: undefined,
    text: "",
  },
  user: {
    email: "",
  },
  token: "",
  isLoggingIn: false,
  lang: {
    locale: "en-GB",
    id: 4,
  },
  globalLangs: [],
  globalSettings: {
    cloudinaryName: "",
    globalLogoBig: "",
    globalLogoMini: "",
    siteLogoBig: "",
    siteLogoMini: "",
    welcomeText1: "",
    welcomeText2: "",
    autoSummary: false,
    autoTranslation: false,
    SSOActivated: false,
    SSOWelcomeText: "",
  },
  sessionStartedAt: null,
  hasAnimation: false,
};

export function reducer(state = initialState, action: any): IAppState {
  switch (action.type) {
    case REHYDRATE:
      return { ...state, isRehydrated: true };
    case SET_TOKEN:
    case SET_IS_LOADING:
    case SET_IS_SAVING:
    case IS_LOGGING_IN:
    case LOGOUT:
    case SET_LANGUAGE:
    case SET_GLOBAL_LANGUAGES:
    case SET_GLOBAL_SETTINGS:
    case SET_USER:
    case SET_SESSION_STARTED_AT:
    case SET_HAS_ANIMATION:
      return { ...state, ...action.payload };
    case SET_ERROR:
      return { ...state, error: { ...action.payload } };
    default:
      return state;
  }
}

export { initialState as appInitialState, reducer as appReducer };
